.solution-label {
  font-size: 1.75rem;
  line-height: 2rem;
  padding: 0 0 1rem 0;

  .checklist {
    position: absolute;
    z-index: 3;
    overflow: hidden;
    width: $mainWidth;
    height: $mainHeight;
  }

  h2 {
    font-size: 1.75rem;
    text-transform: uppercase;
    font-style: normal;
    font-family: 'MetricHPEBold';
  }
  ul {
    font-size: 1.25rem;
    padding: 0;
    margin: 0;
    li {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      white-space: nowrap;
      list-style-type: none;
      padding: 0 0 0.25rem 0;
      margin: 0;
      label {
        padding: 0 0 0 0.5rem;
      }
    }
  }
}
