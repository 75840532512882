.home {
  overflow: hidden;
  .home-left-panel {
    position: relative;
    background-color: rgba(255, 255, 255, 0.94);

    width: 1400px;
    margin: 100px auto 0 auto;
    height: 800px;
    border: 8px solid $hpeMediumBlue;

    h1 {
      text-transform: uppercase;
      font-size: 4rem;
      padding: 3rem 0 0 0;
    }
    .under {
      border-top: 8px solid $hpeMediumBlue;
      width: 100px;
      margin: 30px 0;
      padding: 0;
    }

    .home-detail-section {
      position: absolute;
      top: 100px;
      left: 77px;
      width: 1235px;

      .home-description {
        padding-top: 0px;
        padding-right: 3rem;
        font-size: 1.5rem;
        line-height: 1.75rem;
        white-space: pre-line;
        column-width: 450px;
      }

      .home-link-button {
        margin: 2rem 0 0 0;
        display: flex;
        justify-content: flex-end;
      }

      .home-logo {
        float: right;
        margin-bottom: 60px;
        img {
          width: 215px;
          height: 90px;
        }
      }
    }
  }

  .home-video-bg {
    position: absolute;
    top: 0;
    left: 0;
    min-width: $mainWidth;
    max-width: $mainWidth;
  }
}
