@keyframes calloutFadeIn {
  from {
    transform: translateX(-50px);
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes buildFadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.solution-box {
  position: absolute;
  padding: 30px;
  background-color: $cardBgColor;
  animation: calloutFadeIn 1s;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  z-index: 3;

  .note {
    white-space: pre-line;
    position: absolute;
    bottom: 0.5rem;
    left: 0.5rem;
    padding: 0.5rem;
    color: $debugColor;
    &::before {
      content: 'NOTE: ';
    }
  }

  h2 {
    font-family: 'MetricHPERegular';
    text-transform: uppercase;
    font-style: normal;
    font-size: 1.25rem;
  }
  ul {
    padding: 0;
    margin: 0;
    font-size: 1.25rem;
    li {
      font-family: 'MetricHPEBold';
      text-transform: uppercase;
      display: flex;
      padding: 0.25rem;
      margin: 0;
      list-style-type: none;
      justify-content: flex-start;
      align-items: center;
      div {
        padding-right: 0.5rem;
      }
    }
  }
  .solution-box-footer {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
  }

  .solution-box-control {
    padding: 1rem;
    &:hover {
      cursor: pointer;
    }
  }

  .solution-box-desc {
    font-size: 1.75rem;
    line-height: 2rem;
    padding: 0 0 1rem 0;
    white-space: pre-line;

    &.build {
      opacity: 0;
      animation: buildFadeIn 1s forwards; /* forwards (animation-fill-mode) retains the style from the last keyframe when the animation ends */
    }

    em {
      text-transform: uppercase;
      font-style: normal;
      font-family: 'MetricHPEBold';
    }
    a {
      font-size: 2rem;
      font-style: normal;
      font-family: 'MetricHPEBold';
      color: $primaryBrandColor;
      text-decoration: none;
      &::after {
        content: ' \2192';
      }
    }
  }

  .solution-box-title {
    text-transform: uppercase;
  }
}
