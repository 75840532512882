.button-custom {
  border-color: $borderColor !important;
  &:hover {
    cursor: pointer;
  }
  &:disabled {
    background-color: $disabledColor !important;
    cursor: not-allowed;
  }
}
