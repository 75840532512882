// color
$debugColor: #ff0000cc;
$viewBackgroundColor: #eeeeee;
$backgroundColor: #000000;
$borderColor: #707070;
$cardBgColor: #ffffff;
$primaryBrandColor: #01a982;
$hpeMediumBlue: #32dac8;
$hpeGrayColor2: #7698a2;
$hpeDarkBlue: #0d5265;
$hpeLightBlue: #7ff9e2;
$panelColor: #ffffff;
$statColor: #333333;
$disabledColor: #cccccc;

$deployOverlayColor: #00000099;

// size
$mainWidth: 1920px;
$mainHeight: 1080px;
