html,
body {
  min-width: $mainWidth;
  min-height: $mainHeight;
  width: $mainWidth;
  height: $mainHeight;
  padding: 0;
  margin: 0;
  background-color: $backgroundColor;
  font-family: 'MetricHPERegular';
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

h1 {
  font-family: 'MetricHPEBold';
  font-size: 3rem;
  line-height: 2.75rem;
  font-style: normal;
  padding: 0px;
  margin: 0px;
}

h2 {
  font-family: 'MetricHPEBold';
  font-size: 2.25rem;
  line-height: 2.25rem;
  font-style: normal;
  padding: 0px;
  margin: 0px;
}

h3 {
  font-family: 'MetricHPERegular';
  font-size: 2rem;
  line-height: 2.5rem;
  font-style: normal;
  padding: 0px;
  margin: 0px;
}

p {
  font-family: 'MetricHPERegular';
  font-size: 1.5rem;
  line-height: 1.75rem;
  padding: 0px;
  margin: 0px;
}

strong {
  font-family: 'MetricHPEBold';
}

.text-style {
  font-size: 50px;
  font-family: 'MetricHPEBold';
  text-transform: uppercase;
  line-height: 0.8;
}

.text-style-1 {
  font-size: 40px;
  font-size: 36px;
  font-family: 'MetricHPEBold';
  line-height: 0.8;
}

.text-style-2 {
  font-size: 30px;
}

.text-style-2-bold {
  font-size: 30px;
  font-family: 'MetricHPEBold';
}

.text-style-3 {
  font-size: 20px;
}

.text-style-3-bold {
  font-size: 20px;
  font-family: 'MetricHPEBold';
}

// remove blue outline in chrome
*:focus {
  outline: 0 !important;
}
