.video-player {
  .wrapper {
    position: relative;
    padding-top: 56.25%; /* keeps 16:9 aspect ratio */
  }

  .player-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .player {
    width: 100%;
    height: 100%;
  }
}
