@keyframes deploymentFadeIn {
  from {
    transform: translateX(-50px);
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.deployment {
  border: 8px solid $primaryBrandColor;
  padding: 2rem 1rem;
  width: 550px;
  background-color: $panelColor;
  left: 1275px;
  top: 150px;
  text-align: center;
  animation: deploymentFadeIn 1s;

  h2 {
    font-size: 2.5rem;
    text-transform: uppercase;
  }
  .deployment-selections {
    padding: 3rem 1rem;
    font-size: 1.75rem;
  }
}
