.checkbox {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: 'MetricHPEBold';
  text-transform: uppercase;
  padding: 0.5rem 0;
  margin: 0;
  &:hover {
    cursor: pointer;
  }
  &.disabled {
    cursor: not-allowed;
  }
  span {
    margin-left: 10px;
  }
}
