.vertical {
  position: relative;
  width: $mainWidth;
  height: $mainHeight;

  .mute-control {
    position: absolute;
    z-index: 3;
    bottom: 40px;
    left: 75px;
    height: 50px;
    width: 50px;
    display: flex;
    &:hover {
      cursor: pointer;
    }
  }

  .checklist {
    position: absolute;
    z-index: 3;
    overflow: hidden;
    width: $mainWidth;
    height: $mainHeight;
  }

  .statistic {
    position: absolute;
    z-index: 3;
    overflow: hidden;
    top: 0;
    left: 0;
    width: $mainWidth;
    height: $mainHeight;
    color: $statColor;
    position: absolute;
    font-family: 'MetricHPERegular';
    font-size: 1.5rem;
    line-height: 1.75rem;
    padding: 0px;
    margin: 0px;
    em {
      text-transform: uppercase;
      font-style: normal;
      font-family: 'MetricHPEBold';
    }
  }

  .vertical-back-button {
    position: absolute;
    top: 10px;
    left: 45px;
    z-index: 5;
  }

  .vertical-solution-lottie-container {
    position: absolute;
    z-index: 2;
  }

  .vertical-solution-menu-container {
    position: absolute;
    top: 100px;
    left: 75px;
    z-index: 5;
  }

  .deployment {
    position: absolute;
    z-index: 10;
  }
  .deploying {
    position: absolute;
    background-color: $deployOverlayColor;
    z-index: 8;
    width: 100%;
    height: 100%;
  }

  .vertical-video-bg {
    position: absolute;
    top: 0;
    left: 0;
    min-width: $mainWidth;
    max-width: $mainWidth;
  }
}
