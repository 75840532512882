.nav-menu {
  border: 8px solid $hpeMediumBlue;
  background-color: $panelColor;
  padding: 30px;
  width: 300px;

  h1 {
    text-transform: uppercase;
    span {
      display: block;
      font-size: 2rem;
      line-height: 1.6rem;
      padding: 0;
      margin: 0;
    }
  }
  h2 {
    font-size: 2rem;
    text-transform: uppercase;
    padding: 0;
    margin: 0;
  }
  p {
    &.description {
      padding: 1rem 0;
    }
    &.cta {
      font-size: 1.25rem;
      text-transform: uppercase;
      padding: 1.5rem 0 0 0;
    }
  }
  .nav-menu-container {
    display: flex;
    justify-content: flex-start;

    .nav-menu-box {
      width: 120px;
      height: 30px;
      margin: 1rem 1rem 1rem 0;
      border-radius: 5px;
      background-color: $hpeGrayColor2;
      &:hover {
        cursor: pointer;
      }
    }
    .active {
      background-color: $hpeMediumBlue;
    }
  }
}
