.view-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 570px;
  border: 8px solid $hpeGrayColor2;

  &:hover {
    border-color: $hpeMediumBlue;
  }
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;

  .view-card-bottom {
    position: relative;
    min-height: 350px;
    padding: 20px 28px 0 29px;

    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    background-color: $cardBgColor;

    h3 {
      font-size: 2rem;
      padding: 0;
      margin: 0;
    }
    h4 {
      text-transform: uppercase;
      font-family: 'MetricHPEBold';
      font-size: 1.5rem;
      padding: 0;
      margin: 0;
    }
    .break {
      display: inline-block;
      border-top: 8px solid $hpeMediumBlue;
      width: 75px;
      margin: 1rem 0;
    }

    .view-card-link-button {
      position: absolute;
      bottom: 23px;
    }
  }

  .view-video {
    height: 500px;
  }
}
