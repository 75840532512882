.view {
  position: relative;
  height: $mainHeight;
  background-color: $viewBackgroundColor;

  .view-back-button {
    position: absolute;
    top: 31px;
    left: 45px;
    z-index: 1;
  }

  .view-cards {
    position: absolute;
    top: 150px;
    left: 0px;
    overflow: hidden;

    .view-card-row {
      display: flex;
      overflow: hidden;
      max-width: $mainWidth;
      padding-left: 72px;
      box-sizing: border-box;

      .view-card-container {
        padding-right: 35px;
      }
    }
  }
}
